.dropdown-menu {
  right: 0;
  left: auto;
}

.header-main header {
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

.header-main .nav-link {
  margin: 5px 0px 5px 20px;
}

.flx-container {
  display: flex;
  flex: 1 1 auto;
}

.flx-column {
  display: flex;
  flex-direction: column;
}

.flx-row {
  display: flex;
  flex-direction: row;
}

.test {
  background-color: #0089d1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.spinner-container {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.login-btn {
  border: 2px solid #489ecd;
  border-radius: 0;
}

.free-trial-btn {
  background-color: #03ba03 !important;
  -webkit-box-shadow: 0px 2px 0px #ccc;
  box-shadow: 0px 2px 0px #ccc;
  color: white !important;
}

.login-form-container {
  padding: 50px 20%;
}

.login-form {
  background-color: white;
  padding: 30px;
  width: 400px;
  margin: auto;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.error-message {
  font-size: 12px;
  color: rgba(185, 50, 50, 0.986);
}

.how-solpay img {
  width: 60px;
}

.section {
  padding: 80px 0px 10px 0px;
}

.video-right {
  padding: 0 20px;
}

.video-right h1 {
  padding-top: 50px;
  font-size: 22px;
  color: #414141;
  font-weight: 700;
}

.video-right a {
  font-size: 15px;
  width: 496px;
  /* color: #414141; */
  background: #03ba00;
  padding: 16px;
  color: #ffffff;
  font-weight: 500;
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.64);
  box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.64);
  border-radius: 5px;
}

.footer {
  background-color: #45484f;
  color: #6f7376;
  padding: 30px 0px 0px 0px;
}

.footer .footer-logo {
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  width: 200px;
}

.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.btn-box {
  background: #fff;
  border-radius: 0;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 33%);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  transition: all 0.3s linear;
}
.btn-box h3 {
  color: #354aa5;
  display: block;
  font-size: 21px;
  margin: 10px 0;
  padding-bottom: 5px;
}
.container,
.container-fluid {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 96%;
  width: 1600px;
}

.dashboard-icon-heading h3 {
  border-bottom: 0px !important;
}
