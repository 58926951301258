.container .superannuation {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.GDKJACBCEIB {
  list-style: outside none none;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  /*margin-left: -45px;*/
  margin: 0 10%;
}

.GDKJACBCEIB li:after {
  content: " ";
  display: block;
  width: 0px;
  height: 0px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 40px solid #f1f1f1;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.GDKJACBCEIB li:before {
  content: " ";
  display: block;
  width: 0px;
  height: 0px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 40px solid #fff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 10px;
  left: 100%;
  z-index: 1;
}

.GDKJACBCEIB li:first-child {
  padding-left: 25px;
  width: auto;
}

.GDKJACBCEIB li {
  color: #777;
  padding: 10px 0px 10px 65px;
  background: none repeat scroll 0% 0% #f1f1f1;
  position: relative;
  display: block;
  float: left;
  /*height: 40px;*/
  height: auto;
  text-align: center;
  width: auto;
}

/* .btn {
  padding: 14px 8px;
} */

.btn-primary {
  color: #fff !important;
  background-color: #337ab7;
  border-color: #2e6da4;
}

/*.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
} */

/*.GDKJACBCFIB:after
    {
        border-left-color: #4CB8CA !important;
    }

    .GDKJACBCFIB
    {
        background: none repeat scroll 0% 0% #4CB8CA !important;
        color: #FFF !important;
    }*/
