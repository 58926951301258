.signup-qoute-part {
  background: #ccc;
  padding: 30px;
  border-radius: 10px;
}
@media screen and (max-width: 992px) {
  .payheadimg {
    margin-top: 30px;
  }
  .monsolp {
    padding: 0 100px;
  }
  .footer-part {
    height: auto !important;
  }
  .ivoid p,
  .ivideoright p {
    text-align: center;
    width: auto !important;
    margin-top: 20px;
  }
  .payhead-part {
    padding: 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .easy-icon li {
    float: none;
    text-align: left;
    width: 270px;
    margin: 10px auto;
  }
}

@media screen and (max-width: 420px) {
  .payhead h2 {
    font-size: 35px;
  }
  .monsolp {
    padding: 0 15px;
  }
  .payhead h2 {
    width: auto !important;
  }
  .testimright {
    padding-right: 10px !important;
  }
  .testimleft {
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 992px) {
  .services img {
    margin-left: 45%;
  }
}

@media screen and (min-width: 767px) and (max-width: 991.99px) {
  #accunt-banner-bg {
    height: 380px;
  }
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
/* 
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff !important;
  background-color: #204d74 !important;
  border-color: #122b40 !important;
} */

.spaceCreator {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.btn-heading {
  font-weight: 500;
  font-size: 14;
}
/* body { */
/* overflow-y: scroll; */
/* scrollbar-width: none; Firefox */
/* -ms-overflow-style: none;  Internet Explorer 10+ */
/* } */
/* body::-webkit-scrollbar { WebKit */
/* width: 0;
  height: 0; */
/* } */
