.dropdown-menu {
  right: 0;
  left: auto;
}

.input-date-picker-container .react-datepicker-wrapper {
  display: block;
}

.tenant-header {
  background-color: #354aa5;
}

.tenant-header .tenant-navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.tenant-header .nav-link {
  color: white !important;
  font-size: 15px;
  /* padding: 10px 15px !important; */
}

.tenant-header .nav-link-active {
  color: #0ff !important;
}

.page-sub-menu .sub-menu {
  background: #fff;
  -webkit-box-shadow: 0 0 6px 2px rgba(70, 82, 157, 0.35);
  box-shadow: 0 0 6px 2px rgba(70, 82, 157, 0.35);
}

.page-sub-menu .sub-menu ul {
  margin: 0;
}

.page-sub-menu .sub-menu .list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.page-sub-menu .sub-menu .list-inline li {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.page-sub-menu .sub-menu .list-inline li a {
  margin-right: 15px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #354aa5;
  padding: 15px;
  display: inline-block;
  position: relative;
  font-size: 14.5px;
  z-index: 1;
}

.page-sub-menu .sub-menu .list-inline li a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: #354aa5;
  z-index: -1;
}

.page-sub-menu .sub-menu .list-inline li a:hover {
  text-decoration: none;
}

.page-sub-menu .sub-menu .list-inline li a:hover:after {
  height: 3px;
}

.page-sub-menu .sub-menu .list-inline li a img {
  height: 19px;
}

.page-sub-menu .sub-menu .list-inline .active a:after {
  height: 3px;
}

.page-header {
  padding: 20px 0px;
}

.page-header h2 {
  color: #354aa5;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 3px;
  letter-spacing: 0.25px;
  margin: 0;
  border-bottom: 1px solid #354aa5;
}

.page-header .page-header-button {
  float: right;
  position: relative;
  bottom: 8px;
  background: #354aa5 none repeat scroll 0 0;
  border: medium none;
  border-radius: 6px 2px;
  color: #fff;
  font-size: 14.25px;
  letter-spacing: 0.35px;
  margin-left: 10px;
  padding: 5px 12px;
  text-shadow: none;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.page-header .page-header-button:hover {
  background: #489ecd;
  color: #fff;
  border-radius: 2px 6px;
}

.data-list {
  padding: 10px 0px 20px 0px;
}

.btn-box {
  background: #fff;
  border-radius: 0;
  -webkit-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.33);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.btn-box:hover {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
}

.btn-box a {
  display: block;
  padding: 15px 15px;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.btn-box a:hover {
  text-decoration: none;
  padding: 15px 0px;
}

.btn-box img {
  height: 48px;
}

.btn-box h3 {
  border-bottom: 1px solid;
  color: #354aa5;
  display: block;
  font-size: 21px;
  margin: 10px 0;
  padding-bottom: 5px;
}

.btn-box p {
  color: #333;
  font-size: 16px;
  margin: 0;
}

.async-validating:after {
  content: "";
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 200;
  height: 16px;
  width: 16px;
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==);
}

.list-progress-container {
  padding: 0px 10% 0px 0px;
  width: 100%;
}

.list-progress-container .progress {
  width: 100%;
  background-color: rgba(32, 173, 202, 0.25);
}

.list-progress-container .progress-bar {
  background-color: #489ecd;
  overflow: visible;
}

.list-action-button {
  color: #354aa5;
  padding: 0px 5px;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
}

.page-tabs-container {
  margin-bottom: 30px;
}

.page-tabs-container .page-tabs ul {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.page-tabs-container .page-tabs ul li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.page-tabs-container .page-tabs ul li span {
  border: 2px solid #354aa5;
  border-radius: 2px;
  color: #354aa5;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: normal;
  padding: 8px 15px 9px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  position: relative;
}

.page-tabs-container .page-tabs ul li span:hover {
  background: #489ecd;
  border: 2px solid #489ecd;
  color: #fff;
}

.page-tabs-container .page-tabs ul .active span {
  background: #489ecd;
  border: 2px solid #489ecd;
  color: #fff;
}

.single-filter-container {
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
  margin-bottom: 10px;
}

.single-filter-container .filter-form-control {
  display: inline;
  width: auto;
}

.table-responsive {
  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid #ddd;
  min-height: 0.01%;
  overflow-x: auto;
}

.list-table {
  -webkit-box-shadow: 0 0 10px -4px rgba(70, 82, 157, 0.7);
  box-shadow: 0 0 10px -4px rgba(70, 82, 157, 0.7);
  margin-bottom: 30px;
}

.list-table thead {
  background: #489ecd;
}

.list-table thead tr th {
  border-bottom: none;
  vertical-align: bottom;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.25px;
  white-space: nowrap;
}

.list-table tbody tr td {
  border: medium none;
  color: #222;
  letter-spacing: 0.25px;
  font-size: 13px;
  white-space: nowrap;
  vertical-align: middle;
}

.footer {
  background: #1976d2;
  text-align: center;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.35px;
}

.footer a {
  font-weight: 500;
  color: #fff;
}

.footer a:hover {
  text-decoration: none;
}

.auto-complete-container {
  position: absolute;
  top: 32px;
  left: 0px;
  right: 0px;
  margin-left: 15px;
  margin-right: 15px;
  z-index: 1000;
  -webkit-box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.15);
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0px;
    text-align: right;
    display: block;
  }
}
