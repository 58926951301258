/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.title-header {
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid;
}
html,
body {
  height: 100%;
}

.page-container {
  padding: 16px 30px 30px 30px;
}

.page-title-header {
  h2 {
    font-size: 21px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  padding-bottom: 8px;
  border-bottom: 1px solid;
}
